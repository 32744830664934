import {
    Error,
    isError,
    NotReachableCpIntegrationError,
    UnexpectedResponseStructureCpIntegrationError,
    WithDefaultCpIntegrationErrors,
} from '@cp-shared-10/common-utilities';
import { AxiosError } from 'axios';

export function parseErrorResponse<TAppErrorCode extends string>(
    errorResponse: AxiosError,
): Error<WithDefaultCpIntegrationErrors<TAppErrorCode>> {
    // In worst case, we were not able to reach the API
    let errorDescription: Error<WithDefaultCpIntegrationErrors<TAppErrorCode>> = NotReachableCpIntegrationError;
    // If we receive a response, we have an unexpected error structure in worst case
    if (errorResponse.response?.data) {
        errorDescription = UnexpectedResponseStructureCpIntegrationError;
    }
    if (errorResponse.response?.data && isError<TAppErrorCode>(errorResponse.response?.data)) {
        errorDescription = errorResponse.response.data;
    }
    return errorDescription;
}
