import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import { Error, WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';

import { AppThunk } from './sliceTypeDefinitions';
import { AxiosRequestConfig } from 'axios';
import { AbstractDataState, InitialState } from './AbstractDataState';
import { parseErrorResponse } from './parseErrorResponse';

type FetchDataType = () => AppThunk;
type UpdateDataType<TBusinessObject> = (data: TBusinessObject) => AppThunk;

type ApiDataSlice<TState, TBusinessObject> = {
    reducer: Reducer<TState>;
    fetchData: FetchDataType;
    updateData: UpdateDataType<TBusinessObject>;
};

type SimpleDataFetchParams<TBusinessObject> = {
    dataName: string;
    fetchCallback: (requestConfig?: AxiosRequestConfig) => Promise<TBusinessObject>;
};

export function getSimpleDataFetchSlice<TBusinessObject, TAppErrorCode extends string>({
    dataName,
    fetchCallback,
}: SimpleDataFetchParams<TBusinessObject>): ApiDataSlice<
    AbstractDataState<TBusinessObject, TAppErrorCode>,
    TBusinessObject
> {
    type ErrorActionPayload = Error<WithDefaultCpIntegrationErrors<TAppErrorCode>>;

    const fetchSlice = createSlice({
        name: dataName,
        initialState: InitialState as AbstractDataState<TBusinessObject, TAppErrorCode>,
        reducers: {
            startFetching(state): void {
                state.isLoading = true;
                state.loadingError = undefined;
                state.hasReceivedResponse = false;
            },
            fetchDataSuccess(state, action: PayloadAction<TBusinessObject>): void {
                (state.data as TBusinessObject) = action.payload;
                state.loadingError = undefined;
                state.isLoading = false;
                state.failedLoadingAttempts = 0;
                state.hasReceivedResponse = true;
            },
            fetchDataFailure(state, action: PayloadAction<ErrorActionPayload>): void {
                state.data = undefined;
                (state.loadingError as Error<WithDefaultCpIntegrationErrors<TAppErrorCode>>) = action.payload;
                state.isLoading = false;
                state.failedLoadingAttempts = state.failedLoadingAttempts + 1;
                state.hasReceivedResponse = true;
            },
            updateData(state, action: PayloadAction<TBusinessObject>): void {
                (state.data as TBusinessObject) = action.payload;
            },
        },
    });

    const {
        fetchDataSuccess,
        fetchDataFailure,
        startFetching,
        updateData: updateDataActionCreator,
    } = fetchSlice.actions;

    const fetchData = (requestConfig?: AxiosRequestConfig): AppThunk => async (dispatch): Promise<void> => {
        try {
            dispatch(startFetching());
            const data: TBusinessObject = await fetchCallback(requestConfig);
            dispatch((fetchDataSuccess as ActionCreatorWithPayload<TBusinessObject>)(data));
        } catch (fetchError) {
            const errorDescription = parseErrorResponse<TAppErrorCode>(fetchError);
            dispatch((fetchDataFailure as ActionCreatorWithPayload<ErrorActionPayload>)(errorDescription));
        }
    };

    const updateData = (data: TBusinessObject): AppThunk => (dispatch): void => {
        dispatch(updateDataActionCreator(data));
    };

    return {
        reducer: fetchSlice.reducer,
        fetchData,
        updateData,
    };
}
